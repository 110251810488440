import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const singleCourseTemplate = `<div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 32px; text-align: center">
<table border=""1 style="margin: 0 auto; border: 1px solid #000; width: 100%;">
    <tr style="border-bottom: 1px solid #000">
      <td ><strong>Lezione nr.{no}</strong></td>
      <td colspan="3"><strong>Corso {course_name} ore {hour_range}</strong></td>
    </tr>
    <tr>
        <td colspan="4" style="font-size: 14px; text-align: left;">
            Programma: {description}
        </td>
    </tr>
    <tr>
        <td style="text-align: right">Didattica:</td>
        <td>{theory}</td>
        <td style="text-align: right">Totale Ore:</td>
        <td>{hours}</td>
    </tr>
  </table>
</div>`

const Practical = ` <div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 32px; text-align: center">
<table border="1" style="width: 100%;margin: 0 auto; border: 1px solid #000; width: 100%;">
    <tr style="border-bottom: 1px solid #000">
      <td ><strong>Prova pratica</strong></td>
      <td ><strong>Effettuata</strong></td>
      <td ><strong>Non Effettuata</strong></td>
      <td ><strong>Firma istruttore</strong></td>
    </tr>
    <tr>
        <td style="font-size: 12px">{name}</td>
        <td style="height: 50px"></td>
        <td style="height: 50px"></td>
        <td style="height: 50px"></td>
    </tr>
  </table>
</div>`
const Libretto = `<html>
<head></head>
<body style="font-family: Arial, Helvetica, sans-serif">
    {rows}
   
</body>
</html>`

const singleRow = ` <table>
        <tbody>
            <tr>
                <td>
                    <img src="{logo}" style="width: 150px" />
                </td>
                <td style="width: 100%; text-align: center">
                    <strong>
                        Libretto personale corso {course}
                    </strong>
                </td>
            </tr>
        </tbody>
    </table>
    <div style="padding-left: 32px; padding-right: 32px; margin-top: 32px">
        <div style="margin: 0 auto; text-align: center">
            <span><i>Corsista:&nbsp; </i><strong>{user}</strong></span>
            <br/><span><i>Corso:&nbsp; </i><strong>{course} Nr. 1/2024</strong></span>
        </div>
    </div>
 
    {lessonMap}
    
    {practical}

    <div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 120px; text-align: center">
       <p style="font-size: 12px; text-align:left;">Giorno ${moment().format('DD/MM/YYYY')} Esame con rilascio del certificato</p>
    </div>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    const mappa = payload.lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course_name}', payload.generalCourse['nome corso']).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    const practicalMap = payload.lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', payload.generalCourse['nome corso']).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    const pdf = await html2pdf().set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true },
    })
        .from(Libretto
                .replaceAll('{rows}', payload.partecipant.map((partecipant, idx) => singleRow.replaceAll('{logo}', `${payload.school.logo}`)
                .replaceAll('{course}', payload.generalCourse['nome corso'])
                .replaceAll('{lessonMap}', mappa)
                .replaceAll('{user}', `${partecipant.first_name} ${partecipant.last_name}`)
                .replaceAll('{practical}', practicalMap)))).toPdf().get('pdf')

    window.open(pdf.output('bloburl'), '_blank');
}
export { Libretto, savePdf }