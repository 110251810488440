import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const SingleRow = (partecipant) =>`
        <tr style="border: 1px solid black; border-collapse:collapse">
            <td style="width: 100px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{id_matricola}</td>
            <td style="width: 130px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{last_name}</td>
            <td style="width: 130px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">{first_name}</td>
            <td style="width: 200px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></td>
        </tr>
        `
const Libretto = `<html>
    <body style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; border: 5px solid #99ff; padding: 12px 24px; width: 800px">
    <div style="padding-left: 2rem; padding-right: 2rem;height: 100%" class="page">    
    <div style="width: 100%; display: block; margin: 0 auto; text-align:center">
            <img src="{logo}" style="width: 150px"/>
        </div>
        <div style="margin-top: 24px; text-align: center"><strong style="font-size: 18px">Registro presenze per esame {nome_corso}</strong></div>
        <div style="margin-top: 12px"><strong style="font-size: 12px; width: 100%; text-align: center">Esame {nome_corso} dalle ore .... : .... alle ore .... : .... in data {exam_day}</strong></div>    
    <table style="border: 1px solid black; width: 100%; border-collapse:collapse; font-size: 10px; font-weight: normal; text-align:left; margin: 0 auto; margin-top: 40px">
                <thead style="border: 1px solid black; border-collapse:collapse">
                    <tr style="border: 1px solid black; border-collapse:collapse">
                        <th style="width: 100px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left"></th>
                        <th style="width: 130px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">COGNOME</th>
                        <th style="width: 130px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">NOME</th>
                        <th style="width: 200px;padding-left:4px; height: 20px;border: 1px solid black; border-collapse:collapse; text-align:left">Firma partecipante</th>
                    </tr>
                </thead>
                <tbody style="border: 1px solid black; border-collapse:collapse">
                    {rows}
                </tbody>
            </table>    
            
        <div style="margin-top: 180px; display: ruby-text;">
            <div style="width: 200px; font-size: 11px">
                <strong>IL DIRETTORE DEL CORSO</strong><br/>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
            </div>
            <div style="width: 200px; font-size: 11px; margin-left: 140px">
                <strong>IL COMMISSARIO/SEGRETARIO</strong><br/>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{docente}</i>
            </div>
            
        </div>
    </div>
    </body>
</html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true },
    })
    .from(Libretto
        .replaceAll('{docente}', payload.teacher)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{nome_corso}', payload.nome_corso)
        .replaceAll('{logo}', payload.school.logo)
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').format('dddd, DD/MM/YYYY')}`)
        .replaceAll('{rows}', payload.partecipant.map((partecipant, idx) => SingleRow(partecipant).replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
            .replaceAll('{docente}', payload.teacher)
            .replaceAll('{direttore}', payload.director)
            .replaceAll('{nome_corso}', payload.nome_corso)
            .replaceAll('{id}', payload.id ? payload.id : `2024/${payload.nome_corso}/1`)
            .replaceAll('{exam_day}', `${moment(payload.course.dataValues.to).add(1, 'days').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').format('DD/MM/YYYY')}`)
            .replaceAll('{expiry}', `${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('dddd')}, ${moment(payload.course.dataValues.to).add(1, 'days').add(5, 'years').format('DD/MM/YYYY')}`)
            
            .replaceAll('{first_name}', `${partecipant.first_name}`)
            .replaceAll('{last_name}', `${partecipant.last_name}`)
            .replaceAll('{birth_place}', `${partecipant.birthplace}`)
            .replaceAll('{birth_province}', `${partecipant.birth_province}`)
            .replaceAll('{birth_province}', `${partecipant.birth_province}`)
            .replaceAll('{matricola}', `${partecipant.matricola}`)
            .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
            .replaceAll('{id_matricola}', `${(idx+1).toString().padStart(3, '0')}/${payload.id ? payload.id : `2024/${payload.nome_corso}/1`}`)
            .replaceAll('{fiscal_code}', `${partecipant.fiscal_code}`)
            .replaceAll('{decretee}', `${payload.decretee}`)
            .replaceAll('{birthday}', `${moment(partecipant.birthday).format('DD/MM/YYYY')}`)
            .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
            // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
            .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
            .replaceAll('{logo}', payload.school.logo)  ).join(''))
        
    ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })
}
export { Libretto, savePdf }